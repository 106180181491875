import React, { memo, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Fact } from '../../hooks/useFactSelector/useFactSelector'
import VideoActions from '../../elements/VideoActions/VideoActions'
import WhosPlaying from '../WhosPlaying/WhosPlaying'
import { Box, Fade, Stack } from '@mui/material'

export type VideoFactProps = {
    fact: Fact
    onNext: () => void
}

const VideoFact: React.FC<VideoFactProps> = ({fact, onNext}) =>  {
    const playerRef = useRef<any>();
    const [show, setShow] = useState(false)
    const [playing, setPlaying] = useState(true)
    const [presenting, setPresenting] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setPresenting(false)
        }, 4000)
    }, [])
    
    return (
        <>
            <Fade in={presenting} mountOnEnter unmountOnExit ><Box><WhosPlaying title={fact.title} /></Box></Fade> 
            <Fade in={!presenting} mountOnEnter unmountOnExit >
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh",
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            right: 0
            , left: 0
        }}><VideoActions show={show} onNext={onNext} onReplay={() => {
                playerRef.current.seekTo(0, 'seconds')
                setPlaying(true)
            }} onPlay={() => setPlaying(true)} onClick={() => setPlaying(false)} />
            <ReactPlayer ref={playerRef} playing={playing} onEnded={() => setShow(true)} repl width="100%" height="100%" url={fact.url} controls={false}/></Stack></Fade>
        </>
    )
}
export default memo(VideoFact)

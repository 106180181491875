import { Stack, Typography } from '@mui/material'
import React, { memo } from 'react'
import ActionArea from '../../elements/ActionArea/ActionArea'
import Image from '../../elements/Image/Image'
import { Fact } from '../../hooks/useFactSelector/useFactSelector'

export type ImageFactProps = {
    fact: Fact
    onNext: () => void
}

const ImageFact: React.FC<ImageFactProps> = ({fact, onNext}) =>  (
    <ActionArea onClick={onNext}>
    <Stack gap={4} justifyContent="center" alignItems="center">
        {!!fact?.image && <Image src={fact.image} width="304px" height="200px" />}
        <Typography variant="h2" color="white">{fact.title}</Typography>
    </Stack></ActionArea>)

export default memo(ImageFact)

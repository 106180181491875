import { Card } from '@mui/material'
import React, { memo, useCallback, useMemo } from 'react'
import useColourGenerator from '../../hooks/useColourGenerator/useColourGenerator'
import useFactSelector, { FactType } from '../../hooks/useFactSelector/useFactSelector'
import ImageFact from '../ImageFact/ImageFact'
import TextFact from '../TextFact/TextFact'
import VideoFact from '../VideoFact/VideoFact'

const FactCard: React.FC = () => {
    const {currentFact, getNewFact} = useFactSelector()
    const {currentColour, getNewColour} = useColourGenerator()

    const onNext = useCallback(() => {
        getNewFact()
        getNewColour()
    }, [getNewFact, getNewColour])

    const factCard = useMemo(() => {
        switch (currentFact?.type) {
            case FactType.TEXT:
                return <TextFact onNext={onNext} fact={currentFact} />
            case FactType.IMAGE:
                return <ImageFact onNext={onNext} fact={currentFact} />
            case FactType.VIDEO:
                return <VideoFact onNext={onNext} fact={currentFact} />
            default:
                return null
        }
    }, [currentFact, onNext])


    return (
        <Card sx={{
            width: "100%",
            height: "100vh",
            background: currentColour,
        }}>
            {factCard}
        </Card>)
}

export default memo(FactCard)

import { CardActionArea, Stack } from '@mui/material'
import React, { memo, PropsWithChildren } from 'react'

export type ActionAreaProps = {
    onClick: () => void
}

const ActionArea: React.FC<PropsWithChildren<ActionAreaProps>> = ({onClick, children}) => (
        <CardActionArea onClick={onClick}>
        <Stack sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh",
            background: 'transparent',
    p: 5,
    textAlign: 'center'
        }}>
            {children}
            </Stack>
        </CardActionArea>
)

export default memo(ActionArea)

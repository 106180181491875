import { Stack } from '@mui/material'
import React, { memo } from 'react'

export interface ImageProps {
    src: string
    cover?: boolean
    width?: number | string
    height?: number | string
    backgroundPositionX?: string
    backgroundPositionY?: string
}

const Image: React.FC<ImageProps> = ({
    src,
    width,
    height,
    cover = false,
    backgroundPositionX = 'center',
    backgroundPositionY = 'center'
}) => {
    return (
        <Stack
            height={height}
            width={width}
            sx={{
                backgroundImage: `url(${src})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX,
                backgroundPositionY,
                backgroundSize: cover ? 'cover' : 'contain'
            }}
        />
    )
}

export default memo(Image)

import { useCallback, useEffect, useState } from 'react'
import outdoorBoys from '../../../static/outdoorBoys.jpeg'
import lando from '../../../static/lando.jpg'

export enum FactType {
    VIDEO = 'VIDEO',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE'
}

export type Fact = {
    type: FactType
    title: string
    image?: string
    url?: string
}

const allFacts: Fact[] = [
{
    type: FactType.IMAGE, 
    title: "Welcome to Aoife's fact app, In memory of the Valley lodge fact app night",
    image: lando
},
{
    type: FactType.TEXT, title: "At age 12, aoife decided to run away from home. She didn’t get too far and ended up sitting out in the back outdoor extension. She kept t the hunger at bay by sitting eating wheat loaf in her coat",
},
{
    type: FactType.TEXT,
    title: "Among her stranger ailments  is sleep paralysis, she once saw Ruby sitting at the end of her bed while staying in her college house in Maynooth"
},
{
    type: FactType.TEXT,
    title: "Aoife has coined the terms for many new words in the English language.\n• Queenie head\n• Butslam\n• Crimmie"
},
{
    type: FactType.TEXT,
    title: "Aoife once staged a protest in her school when she tried to get her whole English class to sign a petition to get rid of their English teacher- it failed."
},
{
    type: FactType.TEXT,
    title: "Aoife’s main mode of transport to school in Carrick everyday was on a mini bus nicknamed the `mole mobile`"
},
{
    type: FactType.TEXT,
    title: "Aoife has had many supernatural encounters in Mount Saint Joseph which she claimed were the work of poltergeists. Some included one time where while she was in the bath and heard a ‘ghost’ come in the back door and pass by the window. Another, when the house slept she heard a ghost cause a ruckus in the kitchen and hallway."
},
{
    type: FactType.TEXT,
    title: "Aoife participated as the lead singer in the world famous band `Obse33ed`"
},
{
    type: FactType.TEXT,
    title: "Aoife used to be so afraid of dogs she would cross the road until she got her first dog ruby."
},
{
    type: FactType.TEXT,
    title: "Aoife was 8lb. 3 when she was born, She walked at 9 months, got her first tooth at a year old and was potty trained before she was 2"
},
{
    type: FactType.TEXT,
    title: "Aoife loved ads on the telly when she was around 2 and would know the ad aas soon as it started and would say the name, One say she looked at her bottle and said \"boots\" because she recognised it from an ad"
},
{
    type: FactType.TEXT,
    title: "She could read before she started scholl and knew a lot of Irish words as well"
},
{
    type: FactType.TEXT,
    title: "Aoife loved her bottle and dummy and only got off the dummy at 3 when she was told it was been given to Santy for the baby resindeers"
},
{
    type: FactType.TEXT,
    title: "Aoife wasn't a good sleeper as a baby and some of the methods used to make her sleep where driving around in the car, pushing her in the buggy ir lyiung with her on the couch watching nursery rhymes, to this day she will conk out on even short drives"
},
{
    type: FactType.TEXT,
    title: "Aoife loved to pose for the camer video and every time the video came out Aoife would appear in front of it"
},
{
    type: FactType.TEXT,
    title: "She did Irish dancing for about a year and speech and drama for a few years, she did piano up unitl grade 5 and singing lessons up to grade 8, she went to Beavers and scouts."
},
{
    type: FactType.TEXT,
    title: "She loved art and always got to the finals in the Mosney community compeition with model making"
},
{
    type: FactType.TEXT,
    title: "She loved quizzes and her and her team won a lot of prizes for the schoold, credit union and the scouts"
},
{
    type: FactType.TEXT,
    title: "She never once got in trouble the whole way through primary or secondary school, her favourite subjects where biology, art, english and music"
},
{
    type: FactType.TEXT,
    title: "When Aoife was young her favorite bands where the Spice Girls, Avril Lavigne, Samantha Mumba, Britney Spears and The Coors, when she was 12 that changed to Guns n Roses, Nirvana, Linken Park, Metallica, Queens of the Stone age, Green Day, Avenged Sevenfold, Alexisonfire etc etc"
},
{
    type: FactType.TEXT,
    title: "Aoife never played sports but loved going to the Cavan GAA games with her Dad, One of her first games it poured rain so much, he put a black bin bag on her"
},
{
    type: FactType.TEXT,
    title: "As a teenager she frequented the fiddlers nightclub and other establishments in carrickmacross"
},
{
    type: FactType.TEXT,
    title: "Aoife has always had a passion for shoes and collected at least a hundred pairs before she moved out. When we were shopping for her confirmation she picked the shoes first and then we had to find an outfit to match the shoes"
},
{
    type: FactType.TEXT,
    title: "As a tween she loved Groovy chick and had the duvet covers and bedroom painted. As a young teen she was obsessed with ‘midnight before Christmas’ and had t-shirts and all the merch. And the room changed to black and white as a tween"
},
{
    type: FactType.IMAGE,
    title: "Aoife's favorite youtube channel is the Outdoor Boys",
    image: outdoorBoys
},
{
    type: FactType.VIDEO,
    title: "Laura",
    url: "https://youtube.com/shorts/hcKb_U9eDbA"
},
{
    type: FactType.VIDEO,
    title: "Shane",
    url: "https://youtu.be/esto1WXHzuM?si=0UqTm9C4oWNTp3wX"
},
{
    type: FactType.VIDEO,
    title: "Paul",
    url: "https://youtu.be/uMuOVryxv9Y?si=Gs9orUBTqV2RIWkz"
},
{
    type: FactType.VIDEO,
    title: "Ciara",
    url: "https://youtu.be/1V93_bDIGJw?si=l-cF-6fukDvYrbMw"
},
{
    type: FactType.VIDEO,
    title: "Finn",
    url: "https://youtu.be/6Hv5PTGOYh4?si=77xr5pvqdEyOkSlP"
},
{
    type: FactType.VIDEO,
    title: "Menna",
    url: "https://youtu.be/E9F8esWzmwQ?si=ZHKzGvkGkw6RV0Y1"
},
{
    type: FactType.VIDEO,
    title: "Josh",
    url: "https://youtu.be/WYPVvhdMRu8?si=D7GipW3Nkv1HD4W1"
},
{
    type: FactType.VIDEO,
    title: "Bosh",
    url: "https://youtu.be/FEJX_84Lqzs?si=u2qDQhr0SCLh2VVq"
}
]

function useFactSelector() {
    const [availableFacts, setAvailableFacts] = useState<Fact[]>(allFacts)
    const [currentFact, setCurrentFact] = useState<Fact>(allFacts[0])

    const getNewFact = useCallback(() => {
        const index = Math.floor(Math.random() * availableFacts.length)
        const newFact = availableFacts[index]
        setCurrentFact(newFact)
        setAvailableFacts(facts => facts?.length <= 1 ? allFacts.filter(fact => fact !== newFact) : facts.filter((_, i) => i !== index))
    }, [availableFacts])

    useEffect(() => {
        if (!currentFact) {
            getNewFact()
        }
    }, [currentFact, getNewFact])
    

    return {currentFact, getNewFact}
}

export default useFactSelector

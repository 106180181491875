import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as ReactDOM.Container);
root.render(
  <React.StrictMode>
    <section>
        <Router />
    </section>
  </React.StrictMode>
)

import React, {
    memo
} from 'react'
import FactCard from '../../components/features/FactCard/FactCard'

const Home: React.FC = () => {
    
return (
        <FactCard />
    )
} 

export default memo(Home)

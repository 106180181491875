import { Typography } from '@mui/material'
import React, { memo } from 'react'
import ActionArea from '../../elements/ActionArea/ActionArea'
import { Fact } from '../../hooks/useFactSelector/useFactSelector'

export type TextFactProps = {
    fact: Fact
    onNext: () => void
}

const TextFact: React.FC<TextFactProps> = ({fact, onNext}) =>  (
    <ActionArea onClick={onNext}>
            <Typography variant="h2" color="white">{fact.title}</Typography>
            </ActionArea>)

export default memo(TextFact)

import { Stack, Typography } from '@mui/material'
import React, { memo } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'

export type WhosPlayingProps = {
    title: string
}

const WhosPlaying: React.FC<WhosPlayingProps> = ({title}) =>  {
    
    return (
        <>
            <ConfettiExplosion />
        <Stack gap={4} sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh",
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            right: 0
            , left: 0
        }}>
            <Typography variant="h2" color="white">
                Coming to a screen near you, {title}`s VIDYA
            </Typography>
        </Stack></>
    )
}
export default memo(WhosPlaying)


import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Suspense, memo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Page from '../components/elements/Page/Page';
import Home from '../pages/Home/Home';
import theme from "../theme/theme";

export enum Routes {
    Home = '/',
}

const Router: React.FC = () => (
        <Page title="Aoife Facts">
            <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense
                    fallback={<></>}
                > 
                    <Switch>
                        <Route
                            path={Routes.Home}
                            component={Home}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
            </Page>
    )

export default memo(Router)

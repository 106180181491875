import { PlayArrow, Replay, SkipNext } from '@mui/icons-material'
import { Box, Button, Fade, Stack, Typography } from '@mui/material'
import React, { memo, PropsWithChildren, useState } from 'react'

export type VideoActionsProps = {
    show: boolean
    onPlay: () => void
    onNext: () => void
    onReplay: () => void
    onClick: () => void
}

const VideoActions: React.FC<PropsWithChildren<VideoActionsProps>> = ({show, onPlay, onNext, onReplay, onClick}) => {
    const [clickShow, setClickShow] = useState(false)


    return (<>
        <Fade in={clickShow || show} mountOnEnter unmountOnExit>
        <Stack gap={4} sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh",
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            right: 0
            , left: 0
        }}>
            <Button variant='contained' onClick={() => {
setClickShow(false)
                onPlay()}}  startIcon={<PlayArrow />} >
            <Typography>
                Play</Typography>
            </Button>
        <Stack gap={4} direction="row" sx={{
            textAlign: 'center',
        }}>
            <Button variant='contained' onClick={() => {
setClickShow(false)
onReplay()}}  startIcon={<Replay />} >
            <Typography>
                Replay</Typography>
            </Button>
            <Button variant='contained' onClick={() => {
                setClickShow(false)
                onNext()}}  endIcon={<SkipNext />}>
                
            <Typography>
                Next</Typography>
            </Button>
            </Stack>
        </Stack>
        </Fade>
        {!clickShow && !show && (<Box sx={{
    height: "100vh",
    background: 'transparent',
    textAlign: 'center',
    position: 'fixed',
    top: 0,
    right: 0
    , left: 0
}}>
<Button variant='text' onClick={() =>{
    onClick()
     setClickShow(true)}} sx={{ height: '100%', width: '100%'  ,background: 'transparent'}} />
</Box>)}
    </>)
 }

export default memo(VideoActions)
import { useCallback, useState, useEffect } from 'react'

function useColourGenerator() {
    const [ currentColour, setCurrentColour ] = useState<string>()
    const getNewColour = useCallback(() => {
        setCurrentColour('#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(0, 6))
        }, [])

        useEffect(() => {
            if (!currentColour) {   
                getNewColour()
            }}, [currentColour, getNewColour]) 

                return{currentColour, getNewColour}
}

export default useColourGenerator

import { createTheme } from "@mui/material";


const theme = createTheme()

theme.typography.h2 = {
    ...theme.typography.h2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7rem',
    },
  };

export default theme;
import { Stack } from '@mui/material'
import React, { memo, PropsWithChildren } from 'react'
import { useSetPageTitle } from '../../hooks/useSetPageTitle/useSetPageTitle'

export interface PageProps {
    title: string
}

const Page: React.FC<PropsWithChildren<PageProps>> = ({ children, title }) => {
    useSetPageTitle(title)

    return (
        <Stack width="100vw" height="100vh">
            {children}
        </Stack>
    )
}

export default memo(Page)
